import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

// axios.defaults.headers.common["api_key"] =
//   "310feba7-30a0-4133-940a-72e85200e69a";
export default new Vuex.Store({
  state: {
    playerResults: {
      results: null,
    },
    activePlayer: null,
    isFetching: false,
    showSearchWithResults: false,
    showTerms: false,
    showPlayerPolicy: false,
    showUserPolicy: false,
  },
  mutations: {
    setSearchResults(state: any, data: any[]) {
      Vue.set(state, "playerResults", data);
    },

    updateActivePlayer(state: any, player: any) {
      Vue.set(state, "activePlayer", player);
    },
    updateIsFetching(state: any, status: boolean) {
      Vue.set(state, "isFetching", status);
    },
    updateShowSearchWithResults(state: any, status: boolean) {
      Vue.set(state, "showSearchWithResults", status);
    },
    updateShowTerms(state: any, isShowTerms: boolean) {
      Vue.set(state, "showTerms", isShowTerms);
    },
    updateShowPlayerPolicy(state: any, isShowPlayerPolicy: boolean) {
      Vue.set(state, "showPlayerPolicy", isShowPlayerPolicy);
    },
    updateShowUserPolicy(state: any, isShowUserPolicy: boolean) {
      Vue.set(state, "showUserPolicy", isShowUserPolicy);
    },
  },
  actions: {
    async searchPlayers({ commit }: any, searchString: string) {
      let results = [];
        try {

          commit("updateIsFetching", true);
          commit("updateShowSearchWithResults", true);
          const baseApi = `${process.env.VUE_APP_APIADDRESS}/v2/players/searchGbe`;
          const searchResults = await axios.get(
            baseApi,
            {
              params: { searchString: searchString },
            }
          );
          results = searchResults.data.result;
          commit("setSearchResults", searchResults.data);
          commit("updateIsFetching", false);


        } catch (error) {
          console.log(error);
        }
        return results.length > 0;

    },
  },
});
