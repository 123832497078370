





















































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class TermsAndConditionsWrapper extends Vue {
 
  get showTerms () {
    return this.$store.state.showTerms;
  }
  get showUserPolicy () {
    return this.$store.state.showUserPolicy;
  }
  get showPlayerPolicy () {
    return this.$store.state.showPlayerPolicy;
  }

  public closeTerms () {
    this.$store.commit('updateShowTerms', false)
    this.$store.commit('updateShowPlayerPolicy', false)
    this.$store.commit('updateShowUserPolicy', false)
  }

}
