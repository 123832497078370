














import { Component, Vue } from "vue-property-decorator";
@Component
export default class FooterBar extends Vue {

  public showTerms () {
    this.$store.commit('updateShowTerms', true);
    this.$store.commit('updateShowPlayerPolicy', false);
    this.$store.commit('updateShowUserPolicy', false);
  }
  
  public showPlayerPolicy() {
    this.$store.commit('updateShowTerms', false);
    this.$store.commit('updateShowPlayerPolicy', true);
    this.$store.commit('updateShowUserPolicy', false);
  }

  public showUserPolicy() {    
    this.$store.commit('updateShowTerms', false);
    this.$store.commit('updateShowPlayerPolicy', false);
    this.$store.commit('updateShowUserPolicy', true);
  }

}
