





































































































import { Component, Vue } from 'vue-property-decorator';
import BaseModal from '@/components/BaseModal.vue';
import UserDetailsCapture from '@/components/UserDetailsCapture.vue';
import TermsAndConditionsWrapper from '@/components/TermsAndConditionsWrapper.vue';

@Component({
  components: {
    BaseModal,
    UserDetailsCapture,
    TermsAndConditionsWrapper,
  },
})
export default class Home extends Vue {
  public searchString = '';
  public showModal = false;



  public showTerms() {
    this.$store.commit('updateShowTerms', true);
  }


  public closeModalPopUp(): void {
    this.showModal = false;
  }

  public searchForPlayer(): void {

    if (!this.searchString) {
      (this.$refs.inputRef as HTMLInputElement).focus();
      return;
    }

    const {count, accessCode} = this.localStorageResults();
    if (count >= 3 && !accessCode) {
      this.showModal = true;
      return;
    }

    this.$store
      .dispatch('searchPlayers', this.searchString)
      .then((response: boolean) => {
        if (response) {
          const currentCount = this.localStorageResults().count;
          let newCount = (currentCount + 1).toString();
          localStorage.setItem(
            '54e931d7-077a-4d9e-93c0-e1d2d27cd71a',
            newCount
          );
        } else {
          return;
        }
      });
  }

  private localStorageResults(): { count: number; accessCode: string | null } {
    return {
      count: parseInt(localStorage.getItem(
      '54e931d7-077a-4d9e-93c0-e1d2d27cd71a'
    ) ||  "0"),
      accessCode: localStorage.getItem(
      'e918d0ce-2fe3-47f1-b4cd-0f161624f7c7'
    )
    };
  }

  public mounted() {
    const {count ,accessCode} = this.localStorageResults();
    if (count >= 5 && !accessCode) {
      this.showModal = true;
      return;
    }
  }



  public getPlayerDetails(details: any) {
    this.$store.commit('updateActivePlayer', details);
    this.$router.push(`/player-details`);
  }

  get searchData() {
    return this.$store.state.playerResults.result;
  }

  get isFetching() {
    return this.$store.state.isFetching;
  }
  get showSearchWithResults() {
    return this.$store.state.showSearchWithResults;
  }

  get showTermsAndConditions () {
    return this.$store.state.showTerms || this.$store.state.showPlayerPolicy;
  }

}
