import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/store";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/player-details",
    name: "Player",
    component: () => import("../views/PlayerDetails.vue"),
    beforeEnter: (to, from, next) => {

      if(store.state.activePlayer === null)
        next({path: '/'})
      else next();


    }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
