import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronLeft,
  faSearch,
  faPaperPlane,
} from "@fortawesome/pro-light-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import Vuelidate from 'vuelidate'
import axios from 'axios';
Vue.use(Vuelidate)

axios.defaults.headers.common["api_key"] =
  "310feba7-30a0-4133-940a-72e85200e69a";

library.add(faSpinnerThird, faChevronLeft, faSearch, faPaperPlane);

Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
