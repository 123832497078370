

































































































































import { Component, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required, email, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';

@Component
export default class UserDetailsCapture extends Vue {
  public userDetails = {
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    termsAgreed: false,
    marketingAgreed: false,
    eeaAgreed: false,
  };
  public isSubmitting = false;
  public isSuccessful = false;
  public accessCode: any = null;
  public showFormTermsError = false;
  public showFormMarketingError = false;
  public showFormEeaError = false;

  @Validations()
  validations = {
    userDetails: {
      firstname: { required, minLength: minLength(1) },
      lastname: { required, minLength: minLength(1) },
      company:{required},
      email: { required, email },
      termsAgreed: {required},
      marketingAgreed: {required},
      eeaAgreed: {required},
    },
  };

  public async submitUserEmail(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid && this.areAllConditionsAgreed) {
      try {
        const baseApi = `${process.env.VUE_APP_APIADDRESS}/gbeEmail/postNewContact`;

        this.isSubmitting = true;
       const {status} = await  axios.post(baseApi, {name: `${this.userDetails.firstname} ${this.userDetails.lastname}`, company: this.userDetails.company , emailAddress: this.userDetails.email})

       if(status === 200) {
         this.isSubmitting = false;
          this.isSuccessful = true;
          this.$v.$reset();
          this.accessCode = Math.floor(100000 + Math.random() * 900000).toString();
          localStorage.setItem(
          'e918d0ce-2fe3-47f1-b4cd-0f161624f7c7',
          this.accessCode
        );
       }
      } catch (error) {
        //
      }
    } else {
      this.showFormTermsError = !this.$v.userDetails.termsAgreed?.$model;
      this.showFormMarketingError = !this.$v.userDetails.marketingAgreed?.$model;
      this.showFormEeaError = !this.$v.userDetails.eeaAgreed?.$model;
    }
  }

  get isSuccesfullAndAccesscode() {
    return this.accessCode !== null && this.isSuccessful;
  }

  get areAllConditionsAgreed() {
    return this.$v.userDetails.termsAgreed?.$model && this.$v.userDetails.marketingAgreed?.$model && this.$v.userDetails.eeaAgreed?.$model;
  }
}
