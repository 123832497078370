











import { Component, Vue } from "vue-property-decorator";
import HeaderBar from "@/components/HeaderBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import TermsAndConditionsWrapper from "@/components/TermsAndConditionsWrapper.vue";

@Component({
  components: {
    HeaderBar,
    FooterBar,
    TermsAndConditionsWrapper
  },
})
export default class App extends Vue {

  get showTerms () {
    return this.$store.state.showTerms || this.$store.state.showPlayerPolicy || this.$store.state.showUserPolicy;
  }
  created() {
    const searchCount = localStorage.getItem("54e931d7-077a-4d9e-93c0-e1d2d27cd71a");
    if (!searchCount) {
      localStorage.setItem("54e931d7-077a-4d9e-93c0-e1d2d27cd71a", "0");
    }
  }
}
